<template>
  <div id="directions">
    <v-toolbar flat>
      <v-row dense>
        <v-col md="6">
          <v-text-field :value="filter.search" @change="value => filter.search = value" placeholder="例：商品ページ修正" filled rounded prepend-inner-icon="mdi-magnify" clearable hide-details dense ></v-text-field>
        </v-col>
        <v-col md="2">
          <v-combobox dense clearable filled rounded hide-details class="mx-1" v-model="filter.sales" :items="filter.post_users" item-text="name" item-value="id" placeholder="営業担当" persistent-placeholder></v-combobox>
        </v-col>
        <v-col md="2">
          <v-combobox dense clearable filled rounded hide-details class="mx-1" v-model="filter.director" :items="filter.post_users" item-text="name" item-value="id" placeholder="担当ディレクター" persistent-placeholder></v-combobox>
        </v-col>
        <v-col md="2">
          <v-select dense clearable filled rounded hide-details persistent-placeholder class="ml-1" v-model="filter.status_id" :items="AllStatus" item-text="status" item-value="id" placeholder="ステータス"></v-select>
        </v-col>
      </v-row>
      <v-spacer />
      <v-btn elevation="0" color="primary" @click="openDirectionDialog()"><v-icon left >mdi-plus-circle</v-icon>新規登録</v-btn>
    </v-toolbar>
    <v-container class="">
        <v-card>
          <v-data-table :headers="headers" :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, 250, 500, 1000], showFirstLastPage: true }" :items="directions" :options.sync="options" :server-items-length="totalPassengers" :loading="loading" class="clickable" @click:row="changeView">
            <!-- 列：会社名 -->
            <template v-slot:item.direction_customer_person_name="{ item }">
              <template v-if="item.direction_customer_person_name">{{ item.direction_customer_person_name }}</template>
              <template v-if="item.end_user"><v-icon small right style="vertical-align: baseline;">mdi-link-variant</v-icon></template>
            </template>
            <!-- 列：代理店名 -->
            <template v-slot:item.agent_id="{ item }">
              <template v-if="item.agent_id">{{ item.agent_id }}</template>
            </template>
            <!-- 列：営業担当 -->
            <template v-slot:item.business_person_id="{ item }">
              <template v-if="item.business_person">
                <div style="white-space: nowrap;">
                  <LoginAvatar :user="item.business_person" :size="32" :avatarClass="['d-inline-block','mr-1']" />
                  {{ item.business_person.name }}
                </div>
              </template>
            </template>
            <!-- 列：担当ディレクター -->
            <template v-slot:item.direction_director_person_id="{ item }">
              <template v-if="item.direction_director_person">
                <div style="white-space: nowrap;">
                  <LoginAvatar :user="item.direction_director_person" :size="32" :avatarClass="['d-inline-block','mr-1']" />
                  {{ item.direction_director_person.name }}
                </div>
              </template>
            </template>
            <!-- 列：納品予定日 -->
            <template v-slot:item.direction_delivery_date="{ item }">
              <template v-if="item.direction_delivery_date">{{ item.direction_delivery_date | moment("YYYY/MM/DD(ddd)") }}</template>
            </template>
            <!-- 列：最大納期 -->
            <template v-slot:item.direction_deadline="{ item }">
              <template v-if="item.direction_deadline">{{ item.direction_deadline | moment("YYYY/MM/DD(ddd)") }}</template>
            </template>
            <!-- 列：状況 -->
            <template v-slot:item.direction_status_mst_id="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="caption">{{ item?.direction_status?.status ?? '－' }}</div>
                </template>
                <span>{{ '進捗率：' + item.progress_percentage + '%' }}</span>
              </v-tooltip>
              <v-progress-linear rounded :color="item?.direction_status?.theme_color ?? 'gray'" :value="item.progress_percentage"></v-progress-linear>
            </template>
            <!-- 列：プラン名 -->
            <template v-slot:item.direction_type="{ item }">
              <template v-if="item.direction_type">{{ item.direction_type }}</template>
            </template>
            <!-- 列：案件種別 -->
            <template v-slot:item.direction_type2="{ item }">
              <template v-if="item.direction_type2">{{ item.direction_type2 }}</template>
            </template>
            <!-- 列：アクション -->
            <template v-slot:item.action="{ item }">
              <v-menu offset-y min-width="160">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon small>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <confirm-dialog @accept="duplicateDirection(item)" type="primary" btnTitle="複製">
                    <template v-slot:title>この制作案件を複製しますか？</template>
                    <template v-slot:message><b>{{ item.direction_name }}</b></template>
                    <template v-slot:activator="{ on }">
                      <v-list-item @click.stop="on">
                        <v-list-item-icon><v-icon small>mdi-content-copy</v-icon></v-list-item-icon>複製
                      </v-list-item>
                    </template>
                  </confirm-dialog>
                  <confirm-dialog @accept="deleteDirection(item)">
                    <template v-slot:title>この制作案件を削除しますか？</template>
                    <template v-slot:message>削除されたデータは永久的に削除され、復元することはできません。</template>
                    <template v-slot:activator="{ on }">
                      <v-list-item v-if="canDeleteDirection(item)" @click.stop="on">
                        <v-list-item-icon><v-icon small>mdi-delete-forever</v-icon></v-list-item-icon>削除
                      </v-list-item>
                    </template>
                  </confirm-dialog>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
    </v-container>
    <component :is="directionDialogComponent.isShow ? 'DirectionDialog' : null" :params="directionDialogComponent.params" @close="closeDirectionDialog()"></component>
  </div>
</template>

<script>
import LoginAvatar from '@/components/LoginAvatar.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import DirectionDialog from '@/components/DirectionDialog.vue'
import axios from '@/plugins/axios'

export default {
  name: 'Directions',
  components: {
    LoginAvatar,
    ConfirmDialog,
    DirectionDialog
  },
  data: () => ({
    key: {
      altKeyOn: false,
      shiftKeyOn: false,
      ctrlKeyOn: false,
      metaKeyOn: false
    },
    options: {},
    totalPassengers: 0,
    loading: false,
    filter: {
      post_users: [],
      search: '',
      sales: null,
      director: null,
      status_id: null
    },
    headers: [
      {
        text: '',
        width: 110,
        value: 'direction_status_mst_id',
        align: 'center'
      },
      {
        text: '会社名',
        width: 200,
        value: 'direction_customer_person_name'
      },
      {
        text: 'プラン名',
        width: 200,
        value: 'direction_type'
      },
      {
        text: '案件種別',
        width: 200,
        value: 'direction_type2'
      },
      {
        text: '営業担当',
        value: 'business_person_id',
        class: 'text-no-wrap'
      },
      {
        text: '担当ディレクター',
        value: 'direction_director_person_id',
        class: 'text-no-wrap'
      },
      {
        text: '納品予定日',
        value: 'direction_delivery_date'
      },
      {
        text: '最大納期',
        value: 'direction_deadline'
      },
      {
        text: '代理店名',
        width: 150,
        value: 'agent_id'
      },
      {
        text: '',
        width: 80,
        value: 'action',
        sortable: false,
        align: 'end'
      }
    ],
    directions: [],
    directionDialogComponent: {
      isShow: false,
      params: {}
    }
  }),
  watch: {
    $route (to, from) {
      this.readDataFromAPI()
    },
    filter: {
      handler () {
        this.options.page = 1
        this.readDataFromAPI()
      },
      deep: true
    },
    options: {
      handler () {
        this.readDataFromAPI()
      },
      deep: true
    }
  },
  computed: {
    loginUserInfo: function () {
      return this.$store.getters.loginUserInfo
    },
    AllStatus: function () {
      return this.$store.getters.getAllDirectionStatus
    }
  },
  methods: {
    changeView: function (item) {
      const routeOption = { name: 'admin:direction_show', params: { id: item.id } }
      if (this.key.altKeyOn === true || this.key.shiftKeyOn === true || this.key.ctrlKeyOn === true || this.key.metaKeyOn === true) {
        window.open(this.$router.resolve(routeOption).href)
      } else {
        this.$router.push(routeOption)
      }
    },
    openDirectionDialog: function () {
      this.directionDialogComponent.isShow = true
    },
    closeDirectionDialog: function () {
      this.directionDialogComponent.params = {}
      this.directionDialogComponent.isShow = false
      this.readDataFromAPI()
    },
    duplicateDirection (item) {
      const self = this
      const formData = new FormData()
      formData.append('_method', 'POST')
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${item.id}/duplicate`, formData)
        .then(() => {
          self.$store.commit('setFlashSuccess', '共有設定を更新しました。')
          self.readDataFromAPI()
        })
        .catch(err => {
          self.$store.commit('setFlashError', self.createApiValidErrorMessage(err.response))
        })
    },
    canDeleteDirection (item) {
      const self = this
      if (item.business_person && item.business_person.id === self.loginUserInfo.id) {
        return true
      }
      if (item.direction_director_person && item.direction_director_person.id === self.loginUserInfo.id) {
        return true
      }
      return false
    },
    deleteDirection (item) {
      const self = this
      self.loading = true
      axios.delete(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${item.id}`)
        .then(res => {
          self.$store.commit('setFlashSuccess', '制作案件を削除しました。')
          self.readDataFromAPI()
        })
        .catch(err => {
          self.$store.commit('setFlashError', '削除することができませんでした。')
          console.log(err)
        })
        .finally(() => {
          self.loading = false
        })
    },
    readDataFromAPI: function () {
      this.loading = true
      const queryParams = {
        sort: this.options.sortBy.length === 0 ? 'id' : this.options.sortBy[0],
        direction: this.options.sortDesc.length === 0 ? 'desc' : (this.options.sortDesc[0] ? 'desc' : 'asc'),
        per_page: this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 0,
        page: this.options.page
      }
      if (this.filter.search) {
        queryParams.query = this.filter.search
      }
      if (this.filter.sales) {
        queryParams.business_person_id = this.filter.sales.id
      }
      if (this.filter.director) {
        queryParams.direction_director_person_id = this.filter.director.id
      }
      if (this.filter.status_id) {
        queryParams.status_id = this.filter.status_id
      }
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction`, {
        params: queryParams
      })
        .then(res => {
          this.directions = res.data.data
          this.totalPassengers = res.data.total
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created: function () {
    const self = this

    // 制作会社ユーザーを取得
    axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/login`, {
      params: {
        per_page: 1000
      }
    })
      .then(res => {
        this.filter.post_users = res.data.data
      })
      .catch(err => {
        console.log(err)
      })

    // キーイベント監視
    self.keyDownHandler = function (event) {
      self.key.altKeyOn = event.altKey
      self.key.shiftKeyOn = event.shiftKey
      self.key.ctrlKeyOn = event.ctrlKey
      self.key.metaKeyOn = event.metaKey
    }
    self.keyUpHandler = function (event) {
      self.key.altKeyOn = event.altKey
      self.key.shiftKeyOn = event.shiftKey
      self.key.ctrlKeyOn = event.ctrlKey
      self.key.metaKeyOn = event.metaKeyOn
    }
    window.addEventListener('keydown', self.keyDownHandler)
    window.addEventListener('keyup', self.keyUpHandler)
  },
  beforeDestroy: function () {
    window.removeEventListener('keydown', this.keyDownHandler)
    window.removeEventListener('keyup', this.keyUpHandler)
  }
}
</script>
